<li
  class="spot-list--item"
  *ngFor="let project of projects; index as i; first as isFirst; last as isLast"
  data-qa-selector="op-project-include-list--item"
  [attr.data-list-selector]="projectListItemIdentifier"
>
  <label
    tabindex="-1"
    class="spot-list--item-action op-project-include-list--item-action"
    (keydown.space)="changeSelected(project)"
    [ngClass]="{
      'spot-list--item-action_disabled': project.disabled,
      'spot-list--item-action_active': (searchableProjectListService.activeItemID$ | async) === project.id
    }"
    [attr.data-list-selector]="projectListActionIdentifier"
    [attr.data-list-disabled]="project.disabled || undefined"
  >
    <spot-tooltip
      class="op-project-include-list--tooltip"
      [alignment]="getTooltipAlignment(project, isFirst, isLast)"
      [disabled]="!project.disabled"
      [dark]="true"
    >
      <ng-container slot="trigger">
        <spot-checkbox
          [tabindex]="-1"
          [checked]="isChecked(project.href) || (includeSubprojects && parentChecked)"
          (change)="changeSelected(project)"
          [disabled]="project.disabled"
          [attr.data-qa-project-include-id]="project.id"
          [attr.data-qa-project-include-checked]="(isChecked(project.href) || (includeSubprojects && parentChecked))  ? 1 : 0"
        ></spot-checkbox>
        <div
          class="spot-list--item-title spot-list--item-title_ellipse-text"
          data-qa-selector="op-project-include-list--item-title"
          [opSearchHighlight]="searchText"
          [title]="project.name"
        >{{ project.name }}</div>
      </ng-container>

      <p
        slot="body"
        class="spot-body-small"
      >
        <span *ngIf="project.href === currentProjectHref else otherMessages">{{ text.current_project }}</span>

        <ng-template #otherMessages>
          <span *ngIf="includeSubprojects && parentChecked else searchMatch">{{ text.include_all_selected }}</span>
          <ng-template #searchMatch>
            <span *ngIf="project.disabled">{{ text.does_not_match_search }}</span>
          </ng-template>
        </ng-template> 
      </p>
    </spot-tooltip>
  </label>

  <ul
    *ngIf="project.children.length"
    op-project-include-list
    [projects]="project.children"
    [selected]="selected"
    [includeSubprojects]="includeSubprojects"
    [parentChecked]="parentChecked || isChecked(project.href)"
    [searchText]="searchText"
    (update)="updateList($event)"
  ></ul>
</li>
